<script>
import { mapGetters } from 'vuex'

import { propValidator, PROP_TYPES } from '@/utils/validators'
import mixins from '@/utils/mixins'
import {
  ARTICLES_FEED_UPDATED_EVENT,
  ARTICLE_PAGE_UPDATED_EVENT,
  REFRESH_ACTION,
  AUTO_REFRESH_UPDATE_CONDITION,
  AUTO_REFRESH_INSTANCE,
  SIGNAL_R_INITIATED_EVENT,
  SPECIAL_REFRESH_CATEGORY_SLUG
} from 'enums/article-auto-refresh'
import { throwPageNotFoundError } from '@/plugins/error'

const SPINNER_DELAY = 500

export default {
  name: 'ARefreshListener',
  mixins: [mixins.articleDataHandler],
  props: {
    articles: propValidator([PROP_TYPES.ARRAY], false),
    article: propValidator([PROP_TYPES.OBJECT], false),
    feedRefreshInProgress: propValidator([PROP_TYPES.BOOLEAN], false),
    pageRefreshInProgress: propValidator([PROP_TYPES.BOOLEAN], false),
    slotRefreshInProgress: propValidator([PROP_TYPES.ARRAY], false),
    fetchDataFn: propValidator([PROP_TYPES.FUNCTION], false),
    targetArticleId: propValidator([PROP_TYPES.STRING], false),
    targetCategorySlug: propValidator([PROP_TYPES.STRING], false),
    targetTagSlug: propValidator([PROP_TYPES.STRING], false),
    targetTermSlug: propValidator([PROP_TYPES.STRING], false),
    targetAuthorSlug: propValidator([PROP_TYPES.STRING], false),
    settings: propValidator([PROP_TYPES.OBJECT])
  },
  data() {
    return {
      handlerToPostpone: () => {},
      handlerByAction: {
        [REFRESH_ACTION.UPDATE_ARTICLE_FEED]: this.updateArticleFeed,
        [REFRESH_ACTION.UPDATE_ARTICLE_FEED_SLOT]: this.updateArticleSlot,
        [REFRESH_ACTION.UPDATE_ARTICLE_PAGE]: this.updateArticlePage,
        [REFRESH_ACTION.THROW_PAGE_NOT_FOUND]: () => {
          throwPageNotFoundError(this)
        }
      },
      compareHandlerByFeedTargetEntity: {
        feedArticleIds: this.handleArticleIds,
        feedCategorySlugs: this.handleCategorySlugs,
        feedAuthorSlugs: this.handleAuthorSlugs,
        feedTagSlugs: this.handleTagSlugs,
        feedTermSlugs: this.handleTermSlugs
      },
      onUpdateArticleFeedCallback: payload => this.onUpdateArticleFeed(payload),
      onUpdateArticlePageCallback: payload => this.onUpdateArticlePage(payload)
    }
  },
  computed: {
    ...mapGetters({
      isOnboardingInProgress: 'onboarding/isOnboardingInProgress'
    }),
    pageRefreshSettings() {
      return this.settings?.pageRefreshSettings || null
    },
    feedRefreshSettings() {
      return this.settings?.feedRefreshSettings || null
    }
  },
  watch: {
    isOnboardingInProgress(newValue) {
      if (newValue) return

      this.handlerToPostpone()
      this.handlerToPostpone = () => {}
    }
  },
  methods: {
    onUpdateArticleFeed(refreshPayload) {
      if (!this.feedRefreshSettings) return

      /**
       * If refreshPayload is null it means that signalR was paused while the browser
       * tab was inactive. And now we have to request updating the content because
       * we don't know if there were any updates during the offline period.
       */
      if (refreshPayload === null) {
        this.updateArticleFeed()
        return
      }
      try {
        if (!this.articles || !this.articles.length) return

        const refreshData = this.getFeedRefreshData({
          refreshPayload,
          articles: this.articles
        })

        if (!refreshData) return

        const { matchConditions, feedMatchEntities, action, data } = refreshData

        const payload = { matchConditions, feedMatchEntities, action, data }

        if (this.isOnboardingInProgress) {
          this.handlerToPostpone = this.feedRefreshHandler.bind(this, payload)

          return
        }

        this.$emit('feed-update-required')
        this.feedRefreshHandler(payload)
      } catch (err) {
        this.$errorHandler(err)
      }
    },
    onUpdateArticlePage(refreshPayload) {
      if (!this.pageRefreshSettings) return

      /**
       * If refreshPayload is null it means that signalR was paused while the browser
       * tab was inactive. And now we have to request updating the content because
       * we don't know if there were any updates during the offline period.
       */
      if (refreshPayload === null) {
        this.updateArticlePage()
        return
      }
      if (!this?.article?.articleId) return

      const refreshData = this.getPageRefreshData({
        refreshPayload,
        article: this.article
      })

      if (!refreshData) return

      const { matchConditions, action, data } = refreshData

      const payload = { matchConditions, action, data }

      this.pageRefreshHandler(payload)

      /** ToDo: throwPageNotFoundError doesn't work properly on client side.
       *  Should be fixed later
       */
    },
    /**
     * We do additional data fetch on client-side to get possible updates because
     * server-side data is cached and might contain the old data.
     */
    initialRequest() {
      this.onUpdateArticleFeed(null)
      this.onUpdateArticlePage(null)
    },
    getFeedRefreshData({ refreshPayload, articles }) {
      try {
        const initialAcc = {
          feedArticleIds: [],
          feedCategorySlugs: [],
          feedAuthorSlugs: [],
          feedTagSlugs: [],
          feedTermSlugs: []
        }
        const {
          feedArticleIds,
          feedCategorySlugs,
          feedAuthorSlugs,
          feedTagSlugs,
          feedTermSlugs
        } = articles.reduce(
          (acc, article) => ({
            feedArticleIds: [...acc.feedArticleIds, article.articleId],
            feedCategorySlugs: [
              ...acc.feedCategorySlugs,
              article.category.slug
            ],
            feedAuthorSlugs: [
              ...acc.feedAuthorSlugs,
              ...(article.author ? [article.author.slug] : [])
            ],
            feedTagSlugs: [...acc.feedTagSlugs, article.articleId],
            feedTermSlugs: [...acc.feedTagSlugs, article.articleId]
          }),
          initialAcc
        )

        const { ArticlesRefreshReason } = refreshPayload
        const feedEntitiesData = {
          feedArticleIds: [...new Set(feedArticleIds)],
          feedCategorySlugs: [...new Set(feedCategorySlugs)],
          feedAuthorSlugs: [...new Set(feedAuthorSlugs)],
          feedTagSlugs: [...new Set(feedTagSlugs)],
          feedTermSlugs: [...new Set(feedTermSlugs)],
          refreshPayload
        }
        const { matchConditions, feedMatchEntities, action } =
          this.feedRefreshSettings?.[ArticlesRefreshReason] || {}

        if (!action || !matchConditions.length) return null

        return {
          matchConditions,
          feedMatchEntities,
          action,
          data: feedEntitiesData
        }
      } catch (err) {
        this.$errorHandler(err)
      }
    },
    getPageRefreshData({ refreshPayload, article }) {
      const { ArticlesRefreshReason } = refreshPayload
      const pageData = { refreshPayload, article }

      const { matchConditions, action } =
        this.pageRefreshSettings?.[ArticlesRefreshReason] || {}

      if (!action || !matchConditions.length) return null

      return {
        matchConditions,
        action,
        data: pageData
      }
    },
    feedRefreshHandler({
      matchConditions,
      feedMatchEntities,
      action,
      data: {
        feedArticleIds,
        feedCategorySlugs,
        feedAuthorSlugs,
        feedTagSlugs,
        feedTermSlugs,
        refreshPayload
      }
    }) {
      if (matchConditions.includes(AUTO_REFRESH_UPDATE_CONDITION.ALWAYS)) {
        return this.handlerByAction[action]({ Id: refreshPayload?.Id })
      }

      const match = this.checkFeedTargetEntityMatch({
        refreshPayload,
        settings: { matchConditions, feedMatchEntities },
        feedData: {
          feedArticleIds,
          feedCategorySlugs,
          feedAuthorSlugs,
          feedTagSlugs,
          feedTermSlugs
        }
      })

      if (match) {
        return this.handlerByAction[action]({ Id: refreshPayload?.Id })
      }
    },
    pageRefreshHandler({ matchConditions, action, data: { refreshPayload } }) {
      if (matchConditions.includes(AUTO_REFRESH_UPDATE_CONDITION.ALWAYS)) {
        return this.handlerByAction[action]({ Id: refreshPayload?.Id })
      }

      const match = this.checkPageTargetEntityMatch({
        refreshPayload,
        settings: matchConditions
      })

      if (match) {
        return this.handlerByAction[action]({ Id: refreshPayload?.Id })
      }
    },
    async updateArticleFeed() {
      try {
        const updatedArticles = await this.getUpdatedData()

        if (!updatedArticles) return

        this.$emit('update:feedRefreshInProgress', true)

        await this.$helper.wait(SPINNER_DELAY)
        this.$emit('update:articles', [...updatedArticles])
        await this.$helper.wait(SPINNER_DELAY)
        this.$emit('feed-refreshed')
      } catch (err) {
        this.$errorHandler(err, this)
      } finally {
        this.$emit('update:feedRefreshInProgress', false)
      }
    },
    async updateArticleSlot({ Id: updatedArticleId }) {
      const articleIndex = this.$_articleDataHandler_getArticleIndexById({
        id: updatedArticleId,
        articles: this.articles
      })

      try {
        const updatedArticles = await this.getUpdatedData()

        if (!updatedArticles) return

        const newUpdatedArticle = updatedArticles.find(
          ({ articleId }) => articleId === updatedArticleId
        )

        if (!newUpdatedArticle) {
          await this.updateArticleFeed()
          return
        }

        this.updateSlotRefreshInProgress({ articleIndex, value: true })
        await this.$helper.wait(SPINNER_DELAY)
        const articlesWithRefreshedArticle = this.$_articleDataHandler_replaceArticleInArticles(
          {
            articles: this.articles,
            article: newUpdatedArticle,
            index: articleIndex
          }
        )
        this.$emit('update:articles', articlesWithRefreshedArticle)
        await this.$helper.wait(SPINNER_DELAY)
        this.$emit('slot-refreshed')
      } catch (err) {
        this.$errorHandler(err, this)
      } finally {
        this.updateSlotRefreshInProgress({ articleIndex, value: false })
      }
    },
    updateSlotRefreshInProgress({ articleIndex, value }) {
      if (!this.slotRefreshInProgress) return

      const updatedSlotRefreshInProgress = this.$helper.replaceElementInArrayByIndex(
        this.slotRefreshInProgress,
        articleIndex,
        value
      )
      this.$emit('update:slotRefreshInProgress', updatedSlotRefreshInProgress)
    },
    async updateArticlePage() {
      try {
        const updatedArticleData = await this.getUpdatedData()

        if (!updatedArticleData) return

        this.$emit('update:pageRefreshInProgress', true)
        await this.$helper.wait(SPINNER_DELAY)
        this.$emit('update:article', { ...this.article, ...updatedArticleData })
        await this.$helper.wait(SPINNER_DELAY)
        this.$emit('page-refreshed')
      } catch (err) {
        this.$errorHandler(err, this)
      } finally {
        this.$emit('update:pageRefreshInProgress', false)
      }
    },
    async getUpdatedData() {
      if (!this.fetchDataFn) return null

      return await new Promise((resolve, reject) => {
        const minSecondsToDelay = +(
          this.$env.REFRESH_ARTICLE_FEED_MIN_SECOND_TO_DELAY || 0
        )
        const maxSecondsToDelay = +(
          this.$env.REFRESH_ARTICLE_FEED_MAX_SECOND_TO_DELAY || 1
        )
        const timeout =
          this.$helper.randomIntFromInterval(
            minSecondsToDelay,
            maxSecondsToDelay
          ) * 1000

        setTimeout(async () => {
          resolve(await this.fetchDataFn())
        }, timeout)
      })
    },
    wasAuthorChanged(AuthorsSlugs = []) {
      return AuthorsSlugs.length > 1
    },
    getTargetMatch({ targetValue, refreshValue, settings }) {
      if (
        !targetValue ||
        !settings.includes(AUTO_REFRESH_UPDATE_CONDITION.TARGET_MATCH)
      ) {
        return false
      }

      if (refreshValue === SPECIAL_REFRESH_CATEGORY_SLUG.ALL) return true

      return this.$helper.isArray(refreshValue)
        ? refreshValue.some(
            value => value?.toLowerCase() === targetValue?.toLowerCase()
          )
        : targetValue?.toLowerCase() === refreshValue?.toLowerCase()
    },
    getFeedMatch({ feedValues, refreshValues, settings }) {
      if (!settings.includes(AUTO_REFRESH_UPDATE_CONDITION.FEED_MATCH)) {
        return false
      }

      return this.$helper.isArray(refreshValues)
        ? feedValues.some(feedValue => refreshValues.includes(feedValue))
        : feedValues.includes(refreshValues)
    },
    isFeedShouldBeMatchedByEntity(entity, feedMatchEntities) {
      if (!feedMatchEntities?.length) return false

      return feedMatchEntities.includes(entity)
    },
    handleArticleIds(
      { Id },
      feedArticleIds,
      { matchConditions, feedMatchEntities }
    ) {
      const targetMatch = this.getTargetMatch({
        targetValue: this.targetArticleId,
        refreshValue: Id,
        settings: matchConditions
      })

      const feedMatch =
        this.isFeedShouldBeMatchedByEntity(
          AUTO_REFRESH_INSTANCE.ARTICLE_ID,
          feedMatchEntities
        ) &&
        this.getFeedMatch({
          feedValues: feedArticleIds,
          refreshValues: Id,
          settings: matchConditions
        })

      return targetMatch || feedMatch
    },
    handleCategorySlugs(
      { CategorySlug },
      feedCategorySlugs,
      { matchConditions, feedMatchEntities }
    ) {
      const targetMatch = this.getTargetMatch({
        targetValue: this.targetCategorySlug,
        refreshValue: CategorySlug,
        settings: matchConditions
      })

      const feedMatch =
        this.isFeedShouldBeMatchedByEntity(
          AUTO_REFRESH_INSTANCE.CATEGORY_SLUG,
          feedMatchEntities
        ) &&
        this.getFeedMatch({
          feedValues: feedCategorySlugs,
          refreshValues: CategorySlug,
          settings: matchConditions
        })

      return targetMatch || feedMatch
    },
    handleTagSlugs(
      { UpdatedTagsSlugs },
      feedTagSlugs,
      { matchConditions, feedMatchEntities }
    ) {
      const targetMatch = this.getTargetMatch({
        targetValue: this.targetTagSlug,
        refreshValue: UpdatedTagsSlugs,
        settings: matchConditions
      })

      const feedMatch =
        this.isFeedShouldBeMatchedByEntity(
          AUTO_REFRESH_INSTANCE.TAG_SLUG,
          feedMatchEntities
        ) &&
        this.getFeedMatch({
          feedValues: feedTagSlugs,
          refreshValues: UpdatedTagsSlugs,
          settings: matchConditions
        })

      return targetMatch || feedMatch
    },
    handleTermSlugs(
      { UpdatedTermsSlugs },
      feedTermSlugs,
      { matchConditions, feedMatchEntities }
    ) {
      const targetMatch = this.getTargetMatch({
        targetValue: this.targetTermSlug,
        refreshValue: UpdatedTermsSlugs,
        settings: matchConditions
      })

      const feedMatch =
        this.isFeedShouldBeMatchedByEntity(
          AUTO_REFRESH_INSTANCE.TERM_SLUG,
          feedMatchEntities
        ) &&
        this.getFeedMatch({
          feedValues: feedTermSlugs,
          refreshValues: UpdatedTermsSlugs,
          settings: matchConditions
        })

      return targetMatch || feedMatch
    },
    handleAuthorSlugs(
      { AuthorsSlugs },
      feedAuthorSlugs,
      { matchConditions, feedMatchEntities }
    ) {
      const targetMatch = this.getTargetMatch({
        targetValue: this.targetAuthorSlug,
        refreshValue: AuthorsSlugs,
        settings: matchConditions
      })

      const feedMatch =
        this.isFeedShouldBeMatchedByEntity(
          AUTO_REFRESH_INSTANCE.AUTHOR_SLUG,
          feedMatchEntities
        ) &&
        this.getFeedMatch({
          feedValues: feedAuthorSlugs,
          refreshValues: AuthorsSlugs,
          settings: matchConditions
        })

      return targetMatch || feedMatch
    },
    checkFeedTargetEntityMatch({ refreshPayload, settings, feedData }) {
      const feedDetailsArr = Object.entries(feedData)

      return feedDetailsArr.some(([key, feedTargetData]) =>
        this.compareHandlerByFeedTargetEntity[key](
          refreshPayload,
          feedTargetData,
          settings
        )
      )
    },
    isTargetEntityUpdated(targetValue, updatedValues) {
      if (!targetValue) return false

      return this.$helper.isArray(updatedValues)
        ? updatedValues?.includes(targetValue)
        : updatedValues?.toLowerCase() === targetValue?.toLowerCase()
    },
    checkPageTargetEntityMatch({ refreshPayload, settings }) {
      const {
        Id,
        CategorySlug,
        UpdatedTagsSlugs,
        UpdatedTermsSlugs,
        AuthorsSlugs
      } = refreshPayload

      const articleIdMatch = this.isTargetEntityUpdated(
        this.targetArticleId,
        Id
      )
      const categorySlugMatch = this.isTargetEntityUpdated(
        this.targetCategorySlug,
        CategorySlug
      )
      const tagSlugMatch = this.isTargetEntityUpdated(
        this.targetTagSlug,
        UpdatedTagsSlugs
      )
      const termSlugMatch = this.isTargetEntityUpdated(
        this.targetTermSlug,
        UpdatedTermsSlugs
      )
      const authorSlugMatch = this.isTargetEntityUpdated(
        this.targetAuthorSlug,
        AuthorsSlugs
      )

      const results = [
        articleIdMatch,
        categorySlugMatch,
        tagSlugMatch,
        termSlugMatch,
        authorSlugMatch
      ]

      return results.some(result => !!result)
    }
  },
  render() {
    return null
  },
  beforeMount() {
    this.$bus.$on(SIGNAL_R_INITIATED_EVENT, this.initialRequest)
    this.$bus.$on(ARTICLES_FEED_UPDATED_EVENT, this.onUpdateArticleFeedCallback)
    this.$bus.$on(ARTICLE_PAGE_UPDATED_EVENT, this.onUpdateArticlePageCallback)
  },
  async beforeDestroy() {
    this.$bus.$off(SIGNAL_R_INITIATED_EVENT, this.initialRequest)
    this.$bus.$off(
      ARTICLES_FEED_UPDATED_EVENT,
      this.onUpdateArticleFeedCallback
    )
    this.$bus.$off(ARTICLE_PAGE_UPDATED_EVENT, this.onUpdateArticlePageCallback)
  }
}
</script>
